<template>
  <main>
    <package-header title="Request Review" sub-title="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-8 col-md-10">

          <router-link class="d-inline-block mb-3" to="/pages/applications">
            <svg class="icon icon-arrow_cta_back mr-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
            Return to Applications
          </router-link>

          <h2>Request Review for:<br>
              {Document Name}
          </h2>

          <div class="form-group mt-3">
            <label for="">Enter the email address of a myLTSA user to send an invitation to review your document:</label>
            <input type="email" class="form-control col-md-6">
          </div>

          <div class="form-group">
            <div class="custom-control custom-radio">
              <input type="radio" id="canEditandEsign" name="canEdit" class="custom-control-input" checked>
              <label class="custom-control-label" for="canEditandEsign">Can edit and E-sign</label>
            </div>
            <div class="custom-control custom-radio">
              <input type="radio" id="canEdit" name="canEdit" class="custom-control-input">
              <label class="custom-control-label" for="canEdit">Can edit</label>
            </div>

                        <div class="form-text text-muted">
              Note: They will be able to see other documents within the package, but will not be able to access them.
            </div>
          </div>

          <div class="form-group mt-4">
            <label for="">Review and edit the message that {Reviewer Name} will see:</label>
            <textarea name="" id="" cols="30" rows="10" class="form-control">
{Owner} is requesting you to review their document:
{Document Name}

To access the document:

1.  Click on the personally generated link:
{Auto-generated URL}
2.  Log into myLTSA
3.  {Instructions}
            </textarea>
          </div>

          <button class="btn btn-primary">Send Request</button>
          <button class="btn btn-link">Cancel</button>

        </div>
      </div>

      <div class="bg-medium py-2 fixed-bottom" v-if="applicationAdded">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-8 d-sm-flex justify-content-between align-items-center">
              <div class="mr-3 mb-2 mb-sm-0">
                <svg class="icon icon-check text-success"><use xlink:href="/icons/symbol-defs.svg#icon-check"></use></svg>
                {{ applicationSelected }} has been successfully added to your package.
              </div>
              <button class="btn btn-primary">Finish</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Add Applications"></help-panel>
  </main>
</template>

<script>

import PackageHeader from '../../components/PackageHeader.vue'
import HelpPanel from '../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'help-panel': HelpPanel
  }
}
</script>

